export const createOfficerEmail = (details) => {
    const officerName = details.case_officer;
    //escape for edge cases
    if (officerName.toLowerCase().includes('duty')) return 'planning@camden.gov.uk';
    if (officerName.toLowerCase().includes('tree')) return 'planning@camden.gov.uk';

    //for fast track teams

    if (officerName.toLowerCase().includes('fast')) {
        let fastTrackEmail = 'planning@camden.gov.uk';
        if (officerName.toLowerCase().includes('GG')) fastTrackEmail = 'geri.gohin@camden.gov.uk';
        if (officerName.includes('TY')) fastTrackEmail = 'tony.young@camden.gov.uk';
        if (officerName.includes('AM')) fastTrackEmail = 'adrian.malcolm@camden.gov.uk';
        if (officerName.includes('JL')) fastTrackEmail = 'jenna.litherland@camden.gov.uk';
        if (officerName.includes('TC')) fastTrackEmail = 'tania.clifford@camden.gov.uk';
        return fastTrackEmail;
    } 

    const lowerCaseOfficerName = officerName.toLowerCase();
    const emailAddressNames = lowerCaseOfficerName.split(' ');
    const emailAddressNamePart = emailAddressNames.shift() + "." + emailAddressNames.join("");
    const fullEmailAddress = emailAddressNamePart + '@camden.gov.uk';
    return fullEmailAddress;
}