const extractReference = (searchQuery) => {
    const referenceMatches = searchQuery.match(/[0-9][0-9][0-9][0-9]\/[0-9][0-9][0-9][0-9]\/[A-Za-z]/);
    if (referenceMatches) {
        const reference = referenceMatches[0];
        return reference;
    } else {
        return null;
    }
}

const extractIdentifier = (searchQuery) => {
    if (searchQuery.length !== 4) return null;
    const identifierMatches = searchQuery.match(/[0-9][0-9][0-9][0-9]/);
    if (identifierMatches) {
        const identifier = identifierMatches[0];
        return identifier;
    } else {
        return null;
    }
}

const extractNumber = (searchQuery) => {
    const numberMatches = searchQuery.match(/[0-9][0-9]*(?=\s)/);
    if (numberMatches) {
        const firstNumber = numberMatches[0];
        return firstNumber
    } else {
        return null;
    }
}

const streetTypes = [
    //very common
    "street",
    "road",
    "lane",
    "square",
    "mews",
    "close",
    //common
    "walk",
    "way",
    "avenue",
    "drive",
    "gardens",
    "row",
    "circus",
    "alley",
    "place",
    "court",
    "end",
    "grove",
    "green",
    "gate",
    "crescent",
    "cross",
    //uncommon
    "bypass",
    "hill",
    "vale",
    "rise",
    "mead",
    "wharf",
    "side",
    "view",
    "park",
    "meadow",
    "quadrant",
    "gait",
    "wynd",
    "arcade"
]

const extractStreets = (searchQuery) => {
    //this matches a 'street'-like word and the first word before it, not the full street name.
    //remove apostrophes and make case insensitive
    const searchQuerySanitised = searchQuery.replace("'", "").toLowerCase();
    let streetNames = [];
    streetTypes.forEach(streetType => {
        const streetTypeMatches = searchQuerySanitised.match(`[A-Za-z][A-Za-z]*\\s${streetType}`);
        if (streetTypeMatches) {
            const streetName = streetTypeMatches[0];
            streetNames.push(streetName);
        }
    })
    return streetNames;
}

export const parseQuery = (searchQuery) => {
    const identifier = extractIdentifier(searchQuery);
    const reference = extractReference(searchQuery);
    const number = extractNumber(searchQuery);
    const streets = extractStreets(searchQuery);
    return { identifier, reference, number, streets };
}