import Fuse from 'fuse.js';
import { parseQuery } from "./parseQuery";

//2025 addition. search by identifier only
const searchByIdentifier = (searchReference, planningApplications) => {
    let result = {strong: [], weak: []};
    planningApplications.forEach(planningApplication => {
        const planningApplicationReference = planningApplication.properties.application_number;
        const planningApplicationIdentifier = planningApplicationReference.slice(5,9);

        const isExactMatch = (searchReference.toLowerCase() === planningApplicationIdentifier.toLowerCase());

        if (isExactMatch) {
            result.strong.push(planningApplication);
            return;
        }
    })
    return result;
}
//end 2025 addition

const searchByReference = (searchReference, planningApplications) => {
    let result = {strong: [], weak: []};
    planningApplications.forEach(planningApplication => {
        const planningApplicationReference = planningApplication.properties.application_number;
        const isExactMatch = (searchReference.toLowerCase() === planningApplicationReference.toLowerCase());
        
        if (isExactMatch) {
            result.strong.push(planningApplication);
            return;
        }
        const isRelated = (searchReference.slice(0, 9) === planningApplicationReference.slice(0, 9));
        if (isRelated) {
            result.weak.push(planningApplication);
            return;
        }
    })
    return result;
}

const searchByAddress = (searchAddressParsed, planningApplications) => {
    //we expect address, but not necessarily number.
    let results = {
        strong: [],
        weak: []
    };
    planningApplications.forEach(planningApplication => {
        const planningApplicationAddress = planningApplication.properties.development_address;
        const planningApplicationAddressParsed = parseQuery(planningApplicationAddress);
        //now we potentially have a number and a street.
        //this also accounts for when the number isn't present at all!
        const numberMatches = (planningApplicationAddressParsed.number === searchAddressParsed.number);

        const queryStreet = searchAddressParsed.streets[0];
        const streetMatches = planningApplicationAddressParsed.streets.includes(queryStreet);



        const noMatch = (!numberMatches && !streetMatches);
        const strongMatch = (numberMatches && streetMatches);
        const weakMatch = (!strongMatch && streetMatches);

        if (noMatch) return;
        if (strongMatch) results.strong.push(planningApplication);
        if (weakMatch) results.weak.push(planningApplication);
    })
    return results;
}

const generalSearch = (searchQuery, planningApplications) => {
    const fuse = new Fuse(planningApplications, {
        keys: ['properties.development_address'],
        ignoreLocation: true,
        threshold: 0.1
    })
    const results = fuse.search(searchQuery);
    const planningApplicationResults = results.map(result => result.item);
    return {strong: planningApplicationResults, weak: []};
}

export const search = (searchQuery, planningApplications) => {
    //can take a while, so better to be async
    return new Promise((resolve) => {
        //london is too general and causes lag
        let results = {strong: [], weak: []};
        if (!searchQuery || searchQuery.length <= 3) {
            results.strong = [...planningApplications];
            results.strong.sort(function(a,b) {
                return new Date(b.properties.registered_date) - new Date(a.properties.registered_date);
            });
            resolve(results);
        }

        if ("london".includes(searchQuery.toLowerCase())) resolve(results);
        const { identifier, reference, number, streets } = parseQuery(searchQuery);
        if (identifier) {
            results = searchByIdentifier(identifier, planningApplications);
        } else if (reference) {
            results = searchByReference(reference, planningApplications)
        } else if (streets.length) {
            results = searchByAddress({number, streets}, planningApplications);
        } else {
            results = generalSearch(searchQuery, planningApplications);
        }
        resolve(results);
    })
}